/** 数据字典的code */
export enum DictCode {
  /** 模型类型 */
  XrModType = "xr_mod_type",
  /** 模型类型 */
  UserType = "userType",
  /** 性别 */
  Sex = "sex",
}

// 模型类型
export enum XrModType {
  fbx = "0",
  obj = "1",
  glb = "2",
  gltf = "3",
}

export enum XmaType {
  image = "1",
  video = "2",
  audio = "3",
  identify = "4",
  ab = "5",
}

// 分类类型
export enum XstType {
  model = "0",
  scene = "1",
  space = "2",
  ab = "3",
  material = "4",
}
// 音频：audio/
// 图片：img/
// 视频：video/
// 模型：file/model/
// 场景（AB包）：file/scene/
// 空间：file/space/
// 房间：file/room/
export enum UploadPathPrefix {
  image = "img",
  audio = "audio",
  video = "video",
  model = "file/model",
  ab = "file/scene",
  other = "file/other",
}
export enum UploadType {
  local = "local",
  alioss = "alioss",
}
