/** 默认每页显示条数 */
export const defaultPageSize = 20;
/** 页码选择列表 */
// export const defaultPaginationOptions = ['10', '20', '50', '100', '500']
export const defaultPaginationOptions = {
  showQuickJumper: true,
  showSizeChanger: true,
  showLessItems: true,
  pageSizeOptions: ["10", "20", "50", "100", "500"],
  showTotal: (total: number) => {
    return `共 ${total} 条`;
  },
};

// 文件上传相关的配置 --- 可能是需要根据环境进行区分的
// export const uploadConfig = {
//   imgUploadUrl: '/opapi/imgUpload',
//   editImgUploadUrl: '/opapi/editImgUpload',
// }

console.log("process.env.VUE_APP_BASE_API-----", process.env.VUE_APP_BASE_API);
export const apiConfig = {
  // baseUrl: '',
  // baseUrl: '/api',
  baseUrl: process.env.VUE_APP_BASE_API,
  // baseUrl: 'http://ceibs.el.net',
  // baseUrl: 'https://api-dev.ceibsonline.cn',
};

export const uploadType = process.env.VUE_APP_UPLOAD_TYPE;
